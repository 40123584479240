<template>
	<div>
		<b-card no-body>
			<b-card-header>
				<div class="flex-fill">
					<div class="d-flex justify-content-between align-items-center" style="direction: rtl">
						<h4 class="mb-0">
							احصائيات الاشراف في المواقع عبر تطبيق الموبايل ( خاصة بجمع البيانات من المواقع RMS ونظام المعلومات MIS)
						</h4>
						<loading-btn :loading="loading" variant="primary" @click="exportExcel">Export Excel</loading-btn>
					</div>
				</div>
			</b-card-header>
			<table-with-pagination :items="items" rtl>
				<template #thead>
					<tr>
						<th rowspan="2">المشروع</th>
						<th colspan="5">بيانات المشاريع في الخطة</th>
						<th colspan="4">بيانات المشاريع من الواقع</th>
						<th rowspan="2">متوسط سعر الصرف للدولار</th>
					</tr>
					<tr>
						<th>تكلفة</th>
						<th>المنصرف المالي</th>
						<th>عدد العمالة</th>
						<th>تكلفة العمالة</th>
						<th>فرص العمل</th>
						<th>المنصرف المالي</th>
						<th>عدد العمالة</th>
						<th>تكلفة العمالة</th>
						<th>فرص العمل</th>
					</tr>
				</template>
				<template #tbody="{ item }">
					<td>{{ item.project }}</td>
					<td>{{ formatMoney(item.cost, true) }}</td>
					<td>{{ formatMoney(item.disbursements, true) }}</td>
					<td>{{ item.total_labors }}</td>
					<td>{{ formatMoney(item.laber_cost, true) }}</td>
					<td>{{ item.total_working_days }}</td>
					<td>{{ formatMoney(item.items_cost, true) }}</td>
					<td>{{ item.workers }}</td>
					<td>{{ formatMoney(item.workers_cost, true) }}</td>
					<td>{{ item.worker_opp }}</td>
					<td>{{ formatMoney(item.dollar_rate) }}</td>
				</template>
			</table-with-pagination>
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardHeader } from 'bootstrap-vue';
import api from '@/utils/api';
import { formatMoney } from '@/utils/helpers';
import TableWithPagination from '@/components/TableWithPagination.vue';
import LoadingBtn from '@/components/LoadingBtn.vue';

export default {
	name: 'ProjectsInsights',

	components: { BCard, BCardHeader, TableWithPagination, LoadingBtn },

	data: () => ({
		items: [],
		loading: false,
	}),

	async created() {
		this.loadData();
	},

	methods: {
		formatMoney,

		async loadData() {
			const params = new URLSearchParams({ donorId: this.$route.query.donorId ?? '', city: this.$route.query.city ?? '' });
			const { data } = await api.call({
				path: `/reports/project-insight/details?${params}`,
				method: 'GET',
			});

			this.items = data;
		},

		exportExcel() {
			this.loading = true;
			const params = new URLSearchParams({ donorId: this.$route.query.donorId ?? '', city: this.$route.query.city ?? '' });

			api.getFile(`reports/project-insight/details/export?${params}`)
				.then((response) => {
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						}),
					);

					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'project-insight-details');
					document.body.appendChild(link);
					link.click();
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
tr > th {
	vertical-align: middle;
	text-align: center;
}
</style>
